.login-form {

  .forgot-password-text {
    font-family: Inter;
    font-size: 12px;
    line-height: 16px;
    color: #000;
    margin-bottom: 32px;
  }

  .login-btn {
    width: 100%;
  }

  .error-message {
    color: red;
    margin-top: 12px;
    font-size: 14px;
    font-family: InterBold;
    text-align: center;
  }
}