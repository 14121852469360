.invitation-list {
  width: 100%;
  margin-top: 32px;
  margin-bottom: 60px;

  &__headers {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid black;
    border-bottom: 1px solid black;

    div {
      font-family: InterBold;
      font-size: 14px;
      line-height: 18px;
      padding: 16px 0;
    }
  }

  &__body {
    .line-item {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid black;
      padding: 16px 0;
    }

    .line-item-top {
      display: flex;
      justify-content: space-between;

      .email {
        font-family: Inter;
        font-size: 14px;
        line-height: 18px;

        @media(max-width: 767.98px) {
          width: 70%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: left;
        }
      }

      .invited-status {
        color: #001446;
        padding: 2px;
        font-family: Inter;
        font-size: 12px;
        line-height: 16px;
        border: 1px solid #001446;
      }

      .disabled-status {
        color: #f28900;
        padding: 2px;
        font-family: Inter;
        font-size: 12px;
        line-height: 16px;
        border: 1px solid #f28900;
      }

      .declined-status {
        color: #929396;
        padding: 2px;
        font-family: Inter;
        font-size: 12px;
        line-height: 16px;
        border: 1px solid #929396;
      }

      .accepted-status {
        color: #00957d;
        padding: 2px;
        font-family: Inter;
        font-size: 12px;
        line-height: 16px;
        border: 1px solid #00957d;
      }
    }

    .line-item-bottom {
      display: flex;
      justify-content: space-between;
      margin-top: 24px;

      .date {
        font-family: Inter;
        font-size: 14px;
        line-height: 18px;
      }

      .delete-action {
        font-family: InterBold;
        font-size: 14px;
        line-height: 18px;
        text-decoration: underline;
        cursor: pointer;
      }

      .contact-action {
        font-family: Inter;
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}
