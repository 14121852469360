.bottle-shop-container {
  background-image: url("../../assets/vanguard-bottle-shop.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  @media(max-width: 767.98px) {
    background-image: url("../../assets/vanguard-bottle-shop-mobile.png");
    align-items: flex-start;
    //background-position: bottom;
    justify-content: flex-start;
    overflow-y: auto;
    //height: 1572px;
  }

  .header-section {
    padding-top: 32px;
    padding-bottom: 20px;
    display: flex;
    width: 100%;

    @media(max-width: 767.98px) {
      flex-direction: column;
    }

    .link-section {
      width: 33.33%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2000;

      @media(max-width: 767.98px) {
        width: 100%;
        order: 2;
      }

      .icon-text {
        display: flex;
        cursor: pointer;

        img {
          width: 16px;
        }

        .text {
          font-family: InterBold;
          font-size: 14px;
          line-height: 18px;
          color: white;
          text-decoration: underline;
          margin-left: 9px;
        }
      }
    }

    .logo-section {
      width: 33.33%;
      display: flex;
      justify-content: center;

      @media(max-width: 767.98px) {
        width: 100%;
        order: 1;
        margin-bottom: 20px;
      }

      img {
        width: 170px;

        @media(max-width: 767.98px) {
          width: 100px;
        }
      }
    }
  }

  .login-center-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;

    @media(max-width: 767.98px) {
      padding: 16px 16px 0px;
      flex-direction: column;
    }

    @media (min-width: 768px) {
      padding: 0 20px;
    }
    @media (min-width: 900px) {
      padding: 0 60px;
    }
    @media (min-width: 1100px) {
      padding: 0 80px;
    }
    @media (min-width: 1300px) {
      padding: 0 112px;
    }

    .description-login-section {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: center;

      @media(max-width: 767.98px) {
        justify-content: flex-start;
      }
      @media (min-width: 768px) {
        max-width: 350px;
      }
      @media (min-width: 900px) {
        max-width: 400px;
      }
      @media (min-width: 1100px) {
        max-width: 500px;
      }
      @media (min-width: 1300px) {
        max-width: 592px;
      }

      .heading {
        font-family: Cako;
        font-size: 78px;
        line-height: 82px;
        color: #FFF;
        margin-bottom: 24px;

        @media(max-width: 900px) {
          font-size: 58px;
          line-height: 66px;
        }
      }

      .sub-heading {
        font-family: CanelaLight;
        font-size: 20px;
        line-height: 26px;
        color: #FFF;
        margin-bottom: 32px;
      }

      .home-login-btn {
        border: none;
        width: 276px;
        text-transform: none;

        @media(max-width: 767.98px) {
          width: 100%;
        }
      }
    }

    .dialog-login-section {
      background-color: white;
      border: 1px solid rgba(0,0,0,.2);
      padding: 28px 20px 40px;
      flex-direction: column;
      width: 100%;
      border-radius: 0.3rem;

      @media (min-width: 576px) {
        max-width: 383px;
        max-height: 520px;
      }

      @media(max-width: 767.98px) {
        margin-top: 36px;
      }

      .heading {
        font-family: Cako;
        font-size: 34px;
        line-height: 40px;
        text-align: center;
        margin-bottom: 32px;
      }
    }
  }

}