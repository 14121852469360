.form-field {
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;

  label {
    display: flex;
    margin-bottom: 10px;
    font-family: InterBold;
    font-size: 14px;
    line-height: 18px;
  }

  .date-format-label {
    margin-bottom: 10px;
    font-family: InterBold;
    font-size: 14px;
    line-height: 18px;
  }

  input {
    width: 100%;
    border: 1px solid #000;
    height: 48px;
    padding: 16px;
    font-family: Inter;
    font-size: 16px;
    line-height: 20px;
  }

  input:focus {
    outline: none;
    border: 2px solid #000;
  }

  .password-label-and-link {
    display: flex;
    justify-content: space-between;

    .toggle-link {
      margin-bottom: 10px;
      font-family: InterBold;
      font-size: 14px;
      line-height: 18px;
      display: flex;
      cursor: pointer;
      width: 60px;
      justify-content: space-between;

      img {
        width: 16px;
        margin-right: 8px;
      }
    }
  }
}

.custom-checkbox {
  position: relative;
  margin-bottom: 32px;
  display: flex;

  @media (max-width: 767.98px) {
    margin-bottom: 16px;
  }

  span {
    color: #000;
    font-family: Inter;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.2px;
    margin-left: 28px;
  }

  span::before {
    content: '';
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    border: 1px solid #000;
    border-radius: 4px;
  }

  span::after {
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    text-align: center;
  }

  input[type='checkbox'] {
    opacity: 0;
    z-index: -1;
    position: absolute;
  }

  input[type='checkbox']:checked + span::before {
    border: 1px solid #000;
  }

  input[type='checkbox']:hover + span::before {
    border: 1px solid #000;
  }

  input[type='checkbox']:checked + span::after {
    content: '\2714';
    font-size: 14px;
    color: #000;
  }
}

.input-error {
  padding-bottom: 16px;

  input {
    border: 1px solid red;
  }

  input:focus {
    outline: none;
    border: 2px solid red;
  }
}