@font-face {
  font-family: "Cako";
  src: url("../assets/fonts/Cako-Regular.otf") format("opentype");
}

@font-face {
  font-family: "CanelaLight";
  src: url("../assets/fonts/Canela-Light.otf") format("opentype");
}

@font-face {
  font-family: "Canela";
  src: url("../assets/fonts/Canela-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "InterBold";
  src: url("../assets/fonts/Inter-SemiBold.ttf") format("truetype");
}