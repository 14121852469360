.invitations-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  margin: 0 112px;

  @media(max-width: 767.98px) {
    margin: 0 16px;
  }

  &__greeting {
    font-family: Cako;
    font-size: 52px;
    line-height: 60px;
    margin-top: 60px;
  }

  &__invite-text {
    font-family: CanelaLight;
    font-size: 20px;
    line-height: 26px;
    margin-top: 24px;
    width: 560px;
    text-align: center;

    @media(max-width: 767.98px) {
      width: 100%;
    }
  }

  &__heading {
    font-family: Cako;
    font-size: 34px;
    line-height: 40px;
    margin-top: 40px;
  }

  &__invite-details {
    display: flex;
    width: 100%;
    margin-top: 32px;

    @media(max-width: 767.98px) {
      flex-direction: column;
    }

    .invite-numbers {
      display: flex;

      @media(max-width: 767.98px) {
        justify-content: space-around;
        margin-bottom: 20px;
      }

      .total-invited {
        display: flex;
        flex-direction: column;

        .total-invited-title {
          font-family: InterBold;
          font-size: 14px;
          line-height: 18px;
        }

        .total-invited-value {
          font-family: Canela;
          font-size: 22px;
          line-height: 28px;
        }
      }

      .total-accepted {
        display: flex;
        flex-direction: column;
        margin-left: 60px;

        @media(max-width: 767.98px) {
          margin-left: 0px;
        }

        .total-accepted-title {
          font-family: InterBold;
          font-size: 14px;
          line-height: 18px;
        }

        .total-accepted-value {
          font-family: Canela;
          font-size: 22px;
          line-height: 28px;
        }
      }
    }

    .invite-btn {
      width: 164px;

      @media(max-width: 767.98px) {
        width: 100%;
      }
    }

    .invite-used-btn {
      cursor: auto;
    }
  }

  .error-message {
    color: red;
    margin: 12px 0;
    font-size: 14px;
    font-family: InterBold;
    text-align: center;
  }

}

