.footer-container {

  padding: 40px 112px;

  @media(max-width: 767.98px) {
    padding: 32px 16px;
  }

  &__lower {
    display: flex;
    flex-direction: column;

    .link-section {
      display: flex;
      justify-content: space-between;
      margin-top: 24px;
      margin-bottom: 32px;

      @media(max-width: 767.98px) {
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 0;
      }

      .copyright {
        font-family: Inter;
        font-size: 12px;
        line-height: 16px;
        color: #FFF;

        @media(max-width: 767.98px) {
          margin-bottom: 24px;
        }
      }

      .links {
        display: flex;
        align-items: center;

        @media(max-width: 767.98px) {
          justify-content: space-between;
          align-items: flex-start;
        }

        a:not(:last-child) {
          margin-right: 32px;
          font-family: Inter;
          font-size: 12px;
          line-height: 16px;
          color: #FFF;
          text-decoration: none;

          @media(max-width: 767.98px) {
            margin-right: 0;
          }
        }

        .drinkwise {
          display: flex;

          img {
            width: 76px;
          }
        }
      }
    }

    .license-section {
      font-family: Inter;
      font-size: 12px;
      line-height: 16px;
      color: #FFF;
    }
  }


}

.login-page-footer {
  @media(max-width: 768px) {
    padding: 32px 16px;
  }
  @media (min-width: 768px) {
    padding: 32px 20px;
  }
  @media (min-width: 900px) {
    padding: 32px 60px;
  }
  @media (min-width: 1100px) {
    padding: 32px 80px;
  }
  @media (min-width: 1300px) {
    padding: 32px 112px;
  }
}