.button {
  width: 135px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-family: InterBold;
  line-height: 20px;
  border-radius: 0;
  text-align: center;
  border: none;

  &__primary {
    background-color: #001446;
    color: #ffffff;

    &:hover {
      background-color: #bed9e2;
      color: #001446;
      cursor: pointer;
    }

    &:disabled {
      background-color: #929396;
      color: #ffffff;
    }
  }

  &__primary-reverse {
    background-color: #bed9e2;
    color: #001446;

    &:hover {
      background-color: #001446;
      color: #ffffff;
      cursor: pointer;
    }

    &:disabled {
      background-color: #929396;
      color: #ffffff;
    }

    &:focus {
      outline: none;
    }
  }

  &__secondary {
    background-color: #ffffff;
    border: solid 1px #001446;
    color: #001446;

    &:hover {
      background-color: #001446;
      color: #ffffff;
    }

    &:disabled {
      border: solid 1px #929396;
      background-color: #ffffff;
      color: #929396;
    }
  }

  &__tertiary {
    width: auto;
    height: auto;
    color: #001446;
    background-color: transparent;
    text-decoration: underline;

    &:hover {
      color: #000;
    }
  }
}
