@import './fonts';

html {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto !important;
}

*, *:before, *:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.app-flex-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}