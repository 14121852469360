
.delete-modal-body {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  .message {
    font-family: Inter;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 32px;
    text-align: center;
  }

  .delete-confirm-btn {
    width: 100%;
    margin-bottom: 16px;
  }

  .delete-cancel-btn {
    width: 50px;
    margin: auto;
  }

  .error-message {
    margin-top: 16px;
    color: red;
    font-family: Inter;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
  }
}