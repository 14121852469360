.invite-modal-body {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  .checkbox-form-field {
    .custom-checkbox {
      position: relative;
      margin-bottom: 20px;
      display: flex;

      span {
        color: #000;
        font-family: Inter;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.2px;
        margin-left: 28px;
      }

      span::before {
        content: '';
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        top: 0;
        left: 0;
        border: 1px solid #000;
        border-radius: 4px;
      }

      span::after {
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        top: 0;
        left: 0;
        text-align: center;
      }

      input[type='checkbox'] {
        opacity: 0;
        z-index: -1;
        position: absolute;
      }

      input[type='checkbox']:checked + span::before {
        border: 1px solid #000;
      }

      input[type='checkbox']:hover + span::before {
        border: 1px solid #000;
      }

      input[type='checkbox']:checked + span::after {
        content: '\2714';
        font-size: 14px;
        color: #000;
      }
    }

    .error-content {
      margin-bottom: 32px;
    }
  }

  .invite-confirm-btn {
    width: 100%;
    margin-bottom: 16px;
  }

  .invite-cancel-btn {
    width: 100px;
    margin: auto;
  }

  .error-message {
    margin-top: 16px;
    color: red;
    font-family: Inter;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
  }
}
