.modal {
  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 383px;
    }

    .modal-content {
      .modal-header {
        display: flex;
        flex-direction: column;
        border-bottom: none;
        padding: 28px 20px 32px;

        .close-icon {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          cursor: pointer;

          img {
            width: 24px;
          }
        }

        .heading {
          width: 100%;
          display: flex;
          justify-content: center;
          font-family: Cako;
          font-size: 28px;
          line-height: 36px;
          text-align: center;
        }
      }

      .modal-body {
        padding: 0 20px;
      }
    }
  }
}
