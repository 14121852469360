.invitation-table {
  width: 100%;
  margin-top: 32px;
  margin-bottom: 80px;

  table {
    width: 100%;

    .table-heading {
      border-top: 1px solid black;
      border-bottom: 1px solid black;

      th {
        padding: 16px 0px;
        font-family: InterBold;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
      }
    }

    tr {
      border-bottom: 1px solid black;

      td {
        padding: 16px 0px;
        text-align: left;

        .email {
          font-family: Inter;
          font-size: 14px;
          line-height: 18px;
        }

        .date {
          font-family: Inter;
          font-size: 14px;
          line-height: 18px;
        }

        .invited-status {
          color: #001446;
          padding: 2px;
          font-family: Inter;
          font-size: 12px;
          line-height: 16px;
          border: 1px solid #001446;
        }

        .disabled-status {
          color: #f28900;
          padding: 2px;
          font-family: Inter;
          font-size: 12px;
          line-height: 16px;
          border: 1px solid #f28900;
        }

        .declined-status {
          color: #929396;
          padding: 2px;
          font-family: Inter;
          font-size: 12px;
          line-height: 16px;
          border: 1px solid #929396;
        }

        .accepted-status {
          color: #00957d;
          padding: 2px;
          font-family: Inter;
          font-size: 12px;
          line-height: 16px;
          border: 1px solid #00957d;
        }

        .delete-action {
          font-family: InterBold;
          font-size: 14px;
          line-height: 18px;
          text-decoration: underline;
          cursor: pointer;
        }

        .contact-action {
          font-family: Inter;
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }
}
