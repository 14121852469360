.header-container {
  width: 100%;
  background-color: white;
  color: black;

  .main-section {
    padding-top: 32px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 9px 3px rgba(41,41,41,.25);

    .link-section {
      width: 33.33%;
      display: flex;
      justify-content: center;

      .icon-text {
        display: flex;
        cursor: pointer;

        img {
          width: 16px;
        }

        .text {
          font-family: InterBold;
          font-size: 14px;
          line-height: 18px;
          color: black;
          text-decoration: underline;
          margin-left: 9px;
        }
      }
    }

    .logo-section {
      width: 33.33%;
      display: flex;
      justify-content: center;

      img {
        width: 170px;

        @media(max-width: 767.98px) {
          width: 100px;
        }
      }
    }

    .logout-section {
      width: 33.33%;
      display: flex;
      justify-content: center;

      .icon-text {
        display: flex;
        cursor: pointer;

        img {
          width: 16px;
        }

        .text {
          font-family: InterBold;
          font-size: 14px;
          line-height: 18px;
          color: black;
          text-decoration: underline;
          margin-left: 9px;
        }
      }


    }
  }
}